.header-color {
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
   }


   
.landing-grid{
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
   overflow-y: scroll;
  
}
.projects-big-grid{
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}

.projects-grid{
  display:flex;
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  

}

.avatar-img{
  height: 340px;
  padding-top: 5em;
  border-radius: 0% 50% 40% 0%;
  opacity: .7;
  padding: 10px;
}


.banner-text{
  border-radius: 1px;
  box-shadow: 0px 0px 0px 8px rgba(0,0,0,0.3);  
  background-clip: padding-box;
  background-color: black; 
  /* background: url(https://media-exp1.licdn.com/dms/image/C4D1BAQHgVbml3WKvPA/company-background_10000/0?e=2159024400&v=beta&t=CnXEDBMS9A4dmeLn0PVBfSTjxEChZnYeKLMr5c5x30g); */
  opacity: .8;
  width: 95%; 
  padding:auto;
  
  margin: auto;
  border-radius: 10px;
  
  
  
}


.banner-text h1 {
  font-size: 65px;
  font-weight: bold;
  color: white;
  padding: 20px;
  margin-bottom: -10px;
}

.banner-text h2{
  font-size: 45px;
  color:white;
}
.banner-text hr{
   border-top: 5px thin white;
   width: 50%;
   margin:auto;
}
.banner-text p{
  color: white;
  font-size: 20px;
  padding: 1em;
}

.social-links{
display:inline-block;
justify-content: space-between;
/* width: auto; */
margin: auto;


}
.social-links i  {
  color:white;
  font-size:  5em;
  padding: 8px;
  
}

.codewars{
  
  height: 10vmin;
  margin-bottom: 4%;
  padding: 8px;
  border-radius: 50%;
  background: white;
}

.contact-body{
  margin:auto;
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    
  position: fixed;
  width: 100%;
  height: 100%;
  justify-content: center;
  padding-top: 5em;
  overflow-y: scroll hidden;
  
 

}
.contact-grid{
  text-align: center;
  justify-content: center;
  width: 80%;
  height: 550px;
  /* background:  #BBD2C5;; */
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  margin-bottom: 20px;
  
  


}

.contact-grid h1{
  font-family: 'Anton',sans-serif;
  color:white
}

.contact-grid h2 {
  font-family: 'Anton',sans-serif;
  color:white
}

.contact-grid p{
  font-family:  'Oxygen', sans-serif;
  font-weight:bold;
  color:white;
}

.contact-list i{
  font-size: 60 px;
  padding-right:  4rem;
  margin-bottom: 20px;
}
/* Skills page css */
.skills-grid{
  margin:auto;
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  position: center; 
  width: 100%;
  height: 100%;
  /* justify-content: center; */
  padding-top: 2rem;
  color:  white;
  overflow-y: scroll hidden;

}
.skills-grid h1{padding-left: 3rem;}
/* Resume */

/* About Me */
.aboutme-body{
  background: url(https://github.com/dhony05/first-page/blob/master/imgs/img/canion.JPG?raw=true) no-repeat  fixed center;;
  background-size: cover ;
  /* background-attachment: fixed; */
  width: auto;	
  margin:auto;
  


  
  
  
}
.box-container1{
  height: auto;
}
 
.box{
  width: 450px;
  /* background-attachment: fixed; */
  padding:auto;
  text-align: center;
  margin: auto;
  margin-top: 5%; 
  /* background-color: gray; */
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
  /* background: url(https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcRhnEhA3VjBH5ht5qDOUnSnPuUA14hFxO1s6A&usqp=CAU); */
  /* background-repeat: cover no-repeat; */
   opacity: 0.8; 
  color:white;
  font-family: 'Oxygen' , 'sans-serif';

  border-radius: 10px;
} 

.box-img{
  border-radius: 50%;
  width: 250px;
  height: 250px;
  /* opacity: .9; */
  
}

.box h1{
  font-size:  40;
  letter-spacing: 4px;
  font-weight: bold;
  

  
}
.box p{
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 100;
  text-align: justify;

}

@media screen and (max-width:1024px){
  .avatar-img{height: 240px;}
  .banner-text h1{ font-size:235%;}
  .banner-text h2{ font-size:210%;}
  .social-links i {font-size:3em}
  .banner-text p{ font-size: 15px;}
  .banner-text hr{border-top: 5px thick #BBD2C5;}
  .box{width: 300px;}
  .box-img{width: 240px; padding:20px;}
  .box h1{letter-spacing: 2px;font-size:30px ;}
  .box p{letter-spacing: 2px;font-size:16px ; padding: 10px;} 
  .skills-grid{ font-size: 15px;overflow-x: scroll }
  .skills-grid h1{font-size:30px;}
  .projects-grid{padding-top: 1em; display:grid}
  .aboutme-body{ background-size:  auto;}  
  /* .box{overflow-y: scroll hidden} */
  .box-container1{height: 1000px;}
  .contact-body{overflow-y: scroll;}
  .contact-grid{display: flex; height: 750px;overflow-y: scroll hidden;} 
  .contact-grid h1{font-size: 30px; margin: 10px;}
  .contact-grid h2{font-size: 25px; margin:5px; padding: 10px;}
  .contact-me{margin:px;}
  .contact-grid h3{font-size: 20px;}
  .contact-list{margin-top: -50px;}
  .contact-list i{font-size: 20px;display: inline;padding-right:  1rem;}
  .mdl-progress.mdl-js-progress.progress.is-upgraded.mdl-progress.mdl-js-progress.progress.is-upgraded{ width:62%}


}@media(min-width:700px){
  .skills-grid{position:center;}
  .box{padding: 45px}
  .skills-grid{ font-size: 20px; overflow-y: scroll hidden; }
  .mdl-progress.mdl-js-progress.progress.is-upgraded.mdl-progress.mdl-js-progress.progress.is-upgraded{margin-left:"auto"; width:75%}
} 

@media screen and (max-width:1024px){
  .mdl-layout__drawer-button.mdl-layout__drawer-button {
    margin: 3px;
    color: rgb(245, 244, 244);
    
    
}
.mdl-progress.mdl-js-progress.progress.is-upgraded.mdl-progress.mdl-js-progress.progress.is-upgraded{ width:62%}

}

.mdl-layout__content.mdl-layout__content{
  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}
/* Card hover effect for  projects  */
 .projects-grid .mdl-card {

  background: #8E0E00;  /* fallback for old browsers */
  background: -webkit-linear-gradient(to right, #1F1C18, #8E0E00);  /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to right, #1F1C18, #8E0E00); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  
}


.projects-grid .card .face-face1{

  opacity: 0.4;
  transition: 0.5s;
  position: relative;
  transform: translateY(100px)
}

.projects-grid .card:hover .face-face1{
 
  transform: translateY(0px)
}

.projects-grid .card:hover .face-face1{

  background-color: #8E0E00;
  opacity: 1;
  max-width: auto;
}
.projects-grid .card .face-face2{
  opacity: 0;
  transform: translateY(-100px);
}

.projects-grid .card:hover .face-face2{
  opacity: 1;
  transform: translateY(0);
}

/* Social Links animation  */

.ani1{
  animation-duration: 2s;
  animation-iteration-count: infinite;
 

} 

.bouci1:hover{
  animation-name: bouci1;
  animation-timing-function: ease;

}

@keyframes bouci1 {
  0%   { transform: translateY(0); }
  30%  { transform: translateY(-30px); }
  50%  { transform: translateY(0); }
  100% { transform: translateY(0); }
}

.except{
  animation-name: bouci1;
  animation-timing-function: ease;

}

/* Setting springboot icon to gree */
.iconGreen{
  color:greenyellow
}

/* graphql icon */
.icons8-graphql { 
  display: inline-block;
  width: 48px;
  height: 48px;
  background: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHg9IjBweCIgeT0iMHB4Igp3aWR0aD0iNDgiIGhlaWdodD0iNDgiCnZpZXdCb3g9IjAgMCA0OCA0OCIKc3R5bGU9IiBmaWxsOiMwMDAwMDA7Ij48cGF0aCBmaWxsPSIjZmY0MDgxIiBkPSJNMjQuNSw0NS4xNjFMNywzNC44MlYxNC4xOEwyNC41LDMuODM5TDQyLDE0LjE4VjM0LjgyTDI0LjUsNDUuMTYxeiBNOSwzMy42OGwxNS41LDkuMTU5TDQwLDMzLjY4IFYxNS4zMkwyNC41LDYuMTYxTDksMTUuMzJWMzMuNjh6Ij48L3BhdGg+PGNpcmNsZSBjeD0iMjQuNSIgY3k9IjUuNSIgcj0iMy41IiBmaWxsPSIjZmY0MDgxIj48L2NpcmNsZT48Y2lyY2xlIGN4PSIyNC41IiBjeT0iNDMuNSIgcj0iMy41IiBmaWxsPSIjZmY0MDgxIj48L2NpcmNsZT48Y2lyY2xlIGN4PSI4LjUiIGN5PSIzMy41IiByPSIzLjUiIGZpbGw9IiNmZjQwODEiPjwvY2lyY2xlPjxjaXJjbGUgY3g9IjQwLjUiIGN5PSIzMy41IiByPSIzLjUiIGZpbGw9IiNmZjQwODEiPjwvY2lyY2xlPjxjaXJjbGUgY3g9IjguNSIgY3k9IjE1LjUiIHI9IjMuNSIgZmlsbD0iI2ZmNDA4MSI+PC9jaXJjbGU+PGNpcmNsZSBjeD0iNDAuNSIgY3k9IjE1LjUiIHI9IjMuNSIgZmlsbD0iI2ZmNDA4MSI+PC9jaXJjbGU+PHBhdGggZmlsbD0iI2ZmNDA4MSIgZD0iTTQyLjcyLDM1SDYuMjhMMjQuNSwyLjk3OEw0Mi43MiwzNXogTTkuNzIsMzNIMzkuMjhMMjQuNSw3LjAyMkw5LjcyLDMzeiI+PC9wYXRoPjwvc3ZnPg==') 50% 50% no-repeat;
  background-size: 100%; }

  .fa { 
    font-family: FontAwesome;
  }
  